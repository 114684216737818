'use client'

import { Button, CustomSvg, Toast } from '@chaseweb/ui-library/src'
import { useState } from 'react'

interface CopyWidgetProps {
  shareLink: string
}

function fallbackCopyTextToClipboard(text: string) {
  const input = document.createElement('input')
  input.value = text

  // Avoid scrolling to bottom
  input.style.top = '0'
  input.style.left = '0'
  input.style.position = 'fixed'

  document.body.appendChild(input)
  input.focus()
  input.select()

  try {
    // eslint-disable-next-line
    document.execCommand('copy')
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Fallback: Oops, unable to copy', err)
  }

  document.body.removeChild(input)
}

export const CopyWidget = ({ shareLink }: CopyWidgetProps) => {
  const [showToast, setShowToast] = useState(false)

  const handleCopyClick = (event: React.MouseEvent) => {
    if (event.type === 'click') handleCopy()
  }
  const handleCopyKeydown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') handleCopy()
  }

  const handleCopy = async () => {
    setShowToast(true)
    try {
      // Added the try catch to make sure we support Safari IOS 12+
      // eslint-disable-next-line compat/compat
      await globalThis.navigator.clipboard.writeText(shareLink)
    } catch {
      fallbackCopyTextToClipboard(shareLink)
    }
  }

  return (
    <>
      <Button
        onClick={handleCopyClick}
        onKeyDown={handleCopyKeydown}
        noLinkUnderline
        asChild
        variant="link"
        role="button"
        noExternalIcon
        className="tw-text-grey40 focus-visible:tw-text-blue30"
      >
        <CustomSvg
          name="Copy"
          aria-label="Copy this page's link to the clipboard"
        />
      </Button>
      <Toast setShowToast={setShowToast} showToast={showToast} duration={1000}>
        Link copied
      </Toast>
    </>
  )
}
