'use client'
import { ScreenReader } from '@chaseweb/ui-library/src'
import { analytics, UserActionInterfaceType } from '@chaseweb/utils/tracking'
// the one place you should actually import this
// eslint-disable-next-line no-restricted-imports
import NextLink from 'next/link'
import type { PropsWithChildren } from 'react'
import React, { useCallback } from 'react'
import type { UrlObject } from 'url'

import type { RelativePathOrHrefExternalType, RelativePathType } from '@/types'

export const NEW_WINDOW_WARNING_STRING = ' (Opens in new window)'

interface EnhancedUrlObject extends UrlObject {
  pathname?: RelativePathType
}
interface BaseLink extends PropsWithChildren {
  href: RelativePathOrHrefExternalType | EnhancedUrlObject
  target?: '_blank'
  tabIndex?: number
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
  className?: string
  resetFocusAfterClick?: boolean
}

export interface TrackedLinkProps extends BaseLink {
  trackingActionLabel: string
  sectionLabel: string
  notTracked?: never
}

export interface UntrackedLinkProps extends BaseLink {
  trackingActionLabel?: never
  sectionLabel?: never
  notTracked: boolean
}

export type LinkProps = TrackedLinkProps | UntrackedLinkProps

const handleInternalLinkTrailingSlash = (
  to: string,
): RelativePathOrHrefExternalType => {
  if (to.endsWith('.html')) {
    // this should be done at the parseContent level
    return to.replace('.html', '/') as RelativePathOrHrefExternalType
  }
  return to as RelativePathOrHrefExternalType
}

export function Link({
  notTracked,
  trackingActionLabel,
  sectionLabel,
  href,
  target,
  tabIndex,
  onClick,
  children,
  resetFocusAfterClick,
  ...props
}: LinkProps) {
  if (!href && !onClick) {
    console.warn('possible broken link')
  }

  const handleOnClick = useCallback<React.MouseEventHandler<HTMLAnchorElement>>(
    (...args) => {
      if (!notTracked && trackingActionLabel && sectionLabel) {
        analytics.trackAction({
          actionName: trackingActionLabel,
          sectionName: sectionLabel,
          interfaceType: UserActionInterfaceType.LINK,
        })
      }

      if (
        resetFocusAfterClick &&
        document.activeElement &&
        'blur' in document.activeElement &&
        typeof document.activeElement.blur === 'function'
      ) {
        document.activeElement.blur()
      }
      if (onClick) {
        onClick(...args)
      }
    },
    [
      notTracked,
      trackingActionLabel,
      sectionLabel,
      resetFocusAfterClick,
      onClick,
    ],
  )

  if (typeof href === 'string') {
    const isExternal = /^https?:\/\//.test(href) || target === '_blank'
    const isMailTo = href.startsWith('mailto:')
    const isTel = href.startsWith('tel:')

    if (isMailTo || isTel) {
      return (
        <a
          {...props}
          href={href}
          onClick={handleOnClick}
          tabIndex={tabIndex}
          rel="noreferrer"
        >
          {children}
        </a>
      )
    }

    if (isExternal) {
      return (
        <a
          {...props}
          href={href}
          onClick={handleOnClick}
          target="_blank"
          tabIndex={tabIndex}
          rel="noreferrer"
        >
          {children}
          <ScreenReader.Message>
            {NEW_WINDOW_WARNING_STRING}
          </ScreenReader.Message>
        </a>
      )
    }
  }
  const fixedHref =
    typeof href === 'string' ? handleInternalLinkTrailingSlash(href) : href

  return (
    <NextLink
      {...props}
      onClick={handleOnClick}
      tabIndex={tabIndex}
      href={fixedHref}
    >
      {children}
    </NextLink>
  )
}
