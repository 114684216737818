import { forwardRef } from 'react'
import type { HTMLAttributes } from 'react'

import { cn } from '@chaseweb/utils/cn'
import { cva, type VariantProps } from 'class-variance-authority'
import type { HeadingType } from '../../atoms/heading'
import { Heading } from '../../atoms/heading'
import { Button } from '../../atoms/button'

const Root = forwardRef<HTMLDivElement, HTMLAttributes<HTMLElement>>(
  ({ className, ...props }, ref) => {
    return (
      <footer
        ref={ref}
        className={cn(
          'tw-relative tw-shrink-0 tw-overflow-hidden tw-bg-grey90',
          className,
        )}
        {...props}
      />
    )
  },
)
Root.displayName = 'Footer.Root'

const Container = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn('tw-container tw-flex tw-flex-col', className)}
        {...props}
      />
    )
  },
)
Container.displayName = 'Footer.Container'

const LinksContainer = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        'tw-relative tw-flex tw-flex-col tw-justify-between tw-pb-16 tw-pt-4 md:tw-flex-row md:tw-flex-wrap',
        className,
      )}
      {...props}
    />
  )
})
LinksContainer.displayName = 'Footer.LinksContainer'

const LinksColumn = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          'tw-w-full md:tw-w-1/3 md:last:tw-w-auto xl:tw-w-auto',
          className,
        )}
        {...props}
      />
    )
  },
)
LinksColumn.displayName = 'Footer.LinksColumn'

interface ColumnHeaderProps extends HTMLAttributes<HTMLDivElement> {
  headingType?: HeadingType
  asChild?: boolean
}

const ColumnHeader = forwardRef<HTMLDivElement, ColumnHeaderProps>(
  ({ className, headingType = 'h2', children, ...props }, ref) => {
    return (
      <Heading
        ref={ref}
        className={cn(
          'tw-mb-4 tw-mt-12 tw-text-base tw-font-semibold tw-text-grey40',
          className,
        )}
        type={headingType}
        {...props}
      >
        {children}
      </Heading>
    )
  },
)
ColumnHeader.displayName = 'Footer.ColumnHeader'

const LinkList = forwardRef<HTMLUListElement, HTMLAttributes<HTMLUListElement>>(
  ({ className, ...props }, ref) => {
    return (
      <ul
        ref={ref}
        className={cn('tw-flex tw-flex-col', className)}
        {...props}
      />
    )
  },
)
LinkList.displayName = 'Footer.LinkList'

const Link = forwardRef<
  HTMLLIElement,
  HTMLAttributes<HTMLLIElement> & {
    noExternalIcon?: boolean
    asChild?: boolean
  }
>(({ className, asChild, noExternalIcon, ...props }, ref) => {
  return (
    <li ref={ref} {...props}>
      <Button
        className={cn(
          'tw-block tw-py-2 tw-text-grey40 hover:tw-text-blue30',
          className,
        )}
        noLinkUnderline
        asChild={asChild}
        variant="link"
        noExternalIcon={noExternalIcon}
      >
        {props.children}
      </Button>
    </li>
  )
})
Link.displayName = 'Footer.Link'

const separatorVariants = cva(
  'tw-absolute tw-left-0 tw-m-0 tw-h-px tw-w-screen',
  {
    variants: {
      border: {
        default: 'tw-bg-grey60',
        transparent: 'tw-bg-none',
      },
    },
    defaultVariants: {
      border: 'default',
    },
  },
)
interface SeparatorProps
  extends HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof separatorVariants> {}

const Separator = forwardRef<HTMLHRElement, SeparatorProps>(
  ({ className, border, role, ...props }, ref) => {
    return (
      <div>
        <hr
          ref={ref}
          className={cn(
            'tw-border-none',
            separatorVariants({
              border,
            }),
            className,
          )}
          {...props}
        />
      </div>
    )
  },
)
Separator.displayName = 'Footer.Separator'

const Disclaimer = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          'tw-relative tw-flex tw-flex-col tw-gap-4 tw-py-16 tw-text-grey40 [&>*]:tw-m-0 [&>*]:tw-text-sm',
          className,
        )}
        {...props}
      />
    )
  },
)
Disclaimer.displayName = 'Footer.Disclaimer'

export {
  Root,
  Container,
  LinksContainer,
  LinksColumn,
  ColumnHeader,
  LinkList,
  Link,
  Separator,
  Disclaimer,
}
