import { cn } from '@chaseweb/utils/cn'
import type { HTMLAttributes } from 'react'
import { forwardRef } from 'react'

export const Loader = forwardRef<
  HTMLOutputElement,
  HTMLAttributes<HTMLOutputElement> & {
    size?: 'small' | 'large'
    classNameOuterCircle?: string
    classNameInnerCircle?: string
    classNamePaintedEl?: string
  }
>(
  (
    {
      size = 'large',
      className,
      classNameOuterCircle,
      classNameInnerCircle,
      classNamePaintedEl,
      'aria-label': ariaLabel = 'Loading...',
      ...props
    },
    ref,
  ) => (
    <output
      className={cn('tw-m-auto tw-block tw-w-fit', className)}
      ref={ref}
      {...props}
    >
      <div
        aria-hidden="true"
        className={cn(
          'tw-relative tw-h-10 tw-w-10 tw-animate-loader tw-overflow-hidden tw-rounded-full tw-border tw-border-grey80 tw-bg-gradient-to-b tw-from-grey80 tw-to-grey90',
          { 'tw-h-6 tw-w-6': size === 'small' },
          classNameOuterCircle,
        )}
      >
        <div
          className={cn(
            'tw-absolute tw-z-10 tw-h-full tw-w-1/2 tw-bg-blue10',
            classNamePaintedEl,
          )}
        />
        <div
          className={cn(
            'tw-absolute tw-left-1/2 tw-top-1/2 tw-z-20 tw-h-7 tw-w-7 -tw-translate-x-1/2 -tw-translate-y-1/2 tw-rounded-full tw-bg-white',
            { 'tw-h-3.5 tw-w-3.5': size === 'small' },
            classNameInnerCircle,
          )}
        />
      </div>
      <span className="tw-sr-only">{ariaLabel}</span>
    </output>
  ),
)
Loader.displayName = 'Loader'
