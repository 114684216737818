import { memo } from 'react'
import * as SVGConfigProps from './exports.client'
import type { SVGSpriteProps, SvgType } from './types'

const excludePropsIconList: SvgType[] = [
  'ArrowDown',
  'ArrowLeft',
  'ArrowRight',
  'Close',
  'Dash',
  'ExternalLink',
  'Tick',
]

interface SVGProps extends SVGSpriteProps {
  name: SvgType
}

export const CustomSvg = memo(
  ({ name, role, 'aria-label': ariaLabel, children, ...props }: SVGProps) => {
    const compConfigProps = SVGConfigProps[`${name}ConfigProps`]

    const defaultProps = excludePropsIconList.includes(name)
      ? {}
      : {
          role: role ?? 'img',
          'aria-label': ariaLabel ?? name.replace(/([^A-Z])([A-Z])/g, '$1 $2'),
        }

    const allProps = {
      ...defaultProps,
      ...compConfigProps,
      ...props,
    }
    return (
      <svg {...allProps}>
        {children}
        <use href={`#SVG_${name}`} />
      </svg>
    )
  },
)
CustomSvg.displayName = 'CustomSvg'
