'use client'
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSplitStore } from '@chaseweb/utils/split'
import { redirect, usePathname } from 'next/navigation'
import type { ReactNode } from 'react'

export const SplitRedirect = ({ children }: { children?: ReactNode }) => {
  let pathname = usePathname()
  if (pathname.endsWith('/')) {
    pathname = pathname.slice(0, -1)
  }
  const client = useSplitStore((state) => state.clients.user)
  const redirectData = JSON.parse(
    client?.getTreatmentWithConfig('page_redirects').config ?? '[{}]',
  )
  redirectData.forEach((config: any) => {
    if (pathname === config.source) {
      redirect(config.redirectTo)
    }
  })

  const redirect50Data = JSON.parse(
    client?.getTreatmentWithConfig('page_redirects_50').config ?? '[{}]',
  )
  redirect50Data.forEach((config: any) => {
    if (pathname === config.source) {
      redirect(config.redirectTo)
    }
  })

  return client && children
}
