/* eslint-disable no-restricted-imports */
import type { ImageProps as NextImageProps } from 'next/image'
import NextImage from 'next/image'

interface LocalImageProps extends NextImageProps {
  src: Exclude<NextImageProps['src'], string> // disallow remote src
}

/**
 * Slim wrapper around NextImage, only for images with a local asset path
 * (aka files inside of @chaseweb/assets or git).
 *
 * This should not be used for remote images (anything from aem e.g. www.chase.co.uk/_img/12341.jpg),
 * as these images would not be checked if they exist at build time.
 */
export function LocalImage(props: Readonly<LocalImageProps>) {
  return <NextImage {...props} />
}
