import { getUtmParameters, getUtmParametersFromCookie } from '../tracking'
import { SEARCH_ENGINES } from '../tracking/vendor'

export function getMarketingChannel() {
  const utmParams = getUtmParametersFromCookie() ?? getUtmParameters()
  if (utmParams?.utm_medium) return utmParams.utm_medium
  if (utmParams?.pid) return utmParams.pid
  if (typeof window !== 'undefined' && document?.referrer) {
    for (const engine in SEARCH_ENGINES) {
      if (new RegExp(engine, 'i').test(document.referrer))
        return 'organic_search'
    }
    return 'organic_website'
  }
  return 'direct'
}
