'use client'
import { Button, CustomSvg, Heading } from '@chaseweb/ui-library/src'
import { cn } from '@chaseweb/utils/cn'
import { analytics, PartialScreenInterfaceType } from '@chaseweb/utils/tracking'
import { trapFocus } from '@chaseweb/utils/trapFocus'
import { useEffect, useRef } from 'react'

import { AppStoreButton, DownloadQrCode } from '@/components'

export const DownloadPanel = ({
  toggleDownloadPanel,
  show,
}: {
  toggleDownloadPanel: (ev?: unknown) => void
  show: boolean
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (show && containerRef.current) {
      window.document.body.classList.add('ui-no-scroll')
      const dismissTrapFocus = trapFocus(containerRef.current)
      analytics.trackPartialScreen({
        partialScreenName: 'QRCodeSlide',
        interfaceType: PartialScreenInterfaceType.MODAL,
      })
      return () => {
        window.document.body.classList.remove('ui-no-scroll')
        dismissTrapFocus()
      }
    }
  }, [show])

  return (
    <div
      // A workaround for using `inert` until https://github.com/facebook/react/pull/24730 is merged.
      {...{ inert: !show ? '' : undefined }}
      className="tw-absolute tw-inset-y-0"
      ref={containerRef}
    >
      <div
        className={cn(
          'tw-fixed tw-inset-y-0 tw-z-50 tw-w-0 tw-bg-grey10',
          { 'tw-animate-backdropFadeIn tw-opacity-30 lg:tw-w-full': show },
          { 'tw-animate-backdropFadeOut tw-opacity-0 lg:tw-w-0': !show },
        )}
        onClick={toggleDownloadPanel}
        data-testid="panel-overlay"
      />
      <div
        className={cn(
          'tw-fixed tw-inset-y-0 tw-right-0 tw-z-[55] tw-overflow-hidden',
          { 'tw-w-full lg:tw-w-[540px]': show },
          { 'tw-w-0': !show },
        )}
      >
        <div
          className={cn(
            'tw-fixed tw-inset-y-0 tw-right-0 tw-flex tw-w-full lg:tw-w-[540px]',
            { 'tw-animate-slideFromRight': show },
            {
              'tw-translate-x-full tw-duration-200 tw-ease-in-out': !show,
            },
          )}
          data-testid="panel-content"
        >
          <div className="tw-blue-radial-gradient-portrait tw-flex tw-h-full tw-w-full tw-overflow-y-auto tw-p-4 lg:tw-px-12">
            <Button
              variant="link"
              type="button"
              onClick={toggleDownloadPanel}
              aria-label="Close download panel"
              className="tw-absolute tw-right-6 tw-top-6 tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-2xl tw-bg-white tw-text-black tw-outline-2 hover:tw-bg-blue20 hover:tw-text-white focus-visible:tw-outline focus-visible:tw-outline-blue70 active:tw-bg-blue10 active:tw-text-white lg:tw-right-10 lg:tw-top-10"
            >
              <CustomSvg name="Close" />
            </Button>
            <div className="tw-w-full tw-p-4 tw-text-center tw-text-white md:tw-py-12">
              <Heading
                type="h1"
                context="pageTitle"
                className="tw-mb-9 tw-mt-9 tw-text-white xl:tw-min-w-[350px]"
                as="h2"
              >
                Scan. Tap. Open.
              </Heading>
              <div className="tw-mb-9 tw-flex tw-flex-col tw-items-center">
                <p className="tw-max-w-xs lg:tw-max-w-80">
                  Just scan the QR code to download the Chase app – and get one
                  step closer to seeing what your money can do.
                </p>
                <h4 className="tw-mt-4 tw-text-sm tw-leading-[21px]">
                  18+, UK residents.
                </h4>
              </div>
              <div className="tw-mb-9 tw-flex tw-justify-center">
                <div className="tw-flex tw-items-center tw-justify-center">
                  <DownloadQrCode className="sm:tw-w-3/5 md:tw-w-1/2 lg:tw-w-11/12" />
                </div>
              </div>
              <div className="tw-mt-6 tw-flex tw-flex-row tw-justify-center tw-gap-6 tw-py-1">
                <AppStoreButton
                  store="apple"
                  className="tw-outline-2 focus-visible:tw-outline focus-visible:tw-outline-blue70"
                />
                <AppStoreButton
                  store="google"
                  className="tw-outline-2 focus-visible:tw-outline focus-visible:tw-outline-blue70"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
