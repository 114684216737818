'use client'

import type { ComponentPropsWithoutRef, ElementRef } from 'react'
import { forwardRef } from 'react'
import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { cn } from '@chaseweb/utils/cn'
import type { HeadingType } from '@chaseweb/ui-library/src/atoms'
import { CustomSvg, Heading } from '@chaseweb/ui-library/src/atoms'

const Root = forwardRef<
  ElementRef<typeof AccordionPrimitive.Root>,
  ComponentPropsWithoutRef<typeof AccordionPrimitive.Root>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Root
    ref={ref}
    asChild
    className={cn(className)}
    {...props}
  >
    <ul>{children}</ul>
  </AccordionPrimitive.Root>
))
Root.displayName = 'Accordion.Root'

const Item = forwardRef<
  ElementRef<typeof AccordionPrimitive.Item>,
  ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Item
    ref={ref}
    asChild
    className={cn(
      'tw-border-b tw-border-grey10 tw-pb-px last:tw-border-b-0',
      className,
    )}
    {...props}
  >
    <li className="tw-list-none">{children}</li>
  </AccordionPrimitive.Item>
))
Item.displayName = 'Accordion.Item'

interface TriggerProps
  extends ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger> {
  headingType?: HeadingType
}
const Trigger = forwardRef<
  ElementRef<typeof AccordionPrimitive.Trigger>,
  TriggerProps
>(({ className, headingType = 'h3', children, ...props }, ref) => (
  <AccordionPrimitive.Header className={cn('tw-w-full tw-text-xl')} asChild>
    <Heading type={headingType}>
      <AccordionPrimitive.Trigger
        ref={ref}
        className={cn(
          'tw-group/trigger tw-relative tw-flex tw-w-full tw-flex-1 tw-items-center tw-justify-between tw-bg-white tw-py-6 tw-pl-4 tw-pr-12 tw-text-left tw-font-semibold tw-text-text tw-transition-[background] hover:tw-bg-grey90 lg:tw-py-8 lg:tw-pl-8 lg:tw-pr-16',
          'focus-visible:tw-bg-grey90 focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-focusRing',
          className,
        )}
        {...props}
      >
        {children}
        <CustomSvg
          name="ArrowDown"
          className="tw-absolute tw-right-4 tw-w-7 tw-transition-transform group-data-[state=open]/trigger:tw-rotate-180 lg:tw-right-8"
        />
      </AccordionPrimitive.Trigger>
    </Heading>
  </AccordionPrimitive.Header>
))
Trigger.displayName = 'Accordion.Trigger'

const Content = forwardRef<
  ElementRef<typeof AccordionPrimitive.Content>,
  ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className={cn(
      'tw-overflow-hidden tw-transition-all data-[state=closed]:tw-animate-accordion-up data-[state=open]:tw-animate-accordion-down',
    )}
    {...props}
  >
    <div className={cn('tw-px-4 tw-py-6 lg:tw-px-8 lg:tw-py-8', className)}>
      {children}
    </div>
  </AccordionPrimitive.Content>
))
Content.displayName = 'Accordion.Content'

export { Root, Item, Trigger, Content }
