'use client'
import { createContext, forwardRef, useContext } from 'react'
import type {
  ComponentPropsWithoutRef,
  ElementRef,
  HTMLAttributes,
} from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from '@chaseweb/utils/cn'
import { Button } from '../../atoms/button'
import { Heading } from '../../atoms/heading'

type InfoBlockVariantType = 'primary' | 'secondary' | 'tertiary'

const InfoBlockContext = createContext<InfoBlockVariantType>('primary')

const rootVariants = cva(
  'tw-flex tw-flex-col tw-gap-2 tw-rounded-2xl tw-p-6 md:tw-flex-row md:tw-gap-6 md:tw-p-10 lg:tw-rounded-3xl lg:tw-p-12',
  {
    variants: {
      variant: {
        primary: 'tw-bg-blue90',
        secondary: 'tw-bg-grey90',
        tertiary: 'tw-py-0 md:tw-py-0 lg:tw-py-0',
      },
    },
    defaultVariants: {
      variant: 'primary',
    },
  },
)

interface RootProps
  extends HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof rootVariants> {
  variant?: InfoBlockVariantType
  asChild?: boolean
  'data-testid'?: string
}

const Root = forwardRef<HTMLDivElement, RootProps>(
  (
    {
      className,
      asChild,
      'data-testid': dataTestId = 'info-block-root',
      variant = 'primary',
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'div'
    return (
      <InfoBlockContext.Provider value={variant}>
        <Comp
          data-testid={dataTestId}
          ref={ref}
          className={cn(rootVariants({ variant, className }))}
          {...props}
        />
      </InfoBlockContext.Provider>
    )
  },
)
Root.displayName = 'InfoBlock.Root'

interface ImageWrapperProps extends HTMLAttributes<HTMLDivElement> {
  asChild?: boolean
}
const ImageWrapper = forwardRef<HTMLDivElement, ImageWrapperProps>(
  ({ className, asChild, ...props }, ref) => {
    const Comp = asChild ? Slot : 'div'
    return (
      <Comp
        ref={ref}
        className={cn(
          'tw-flex tw-h-20 tw-w-20 tw-shrink-0 tw-items-center md:tw-justify-center lg:tw-h-32 lg:tw-w-32',
          className,
        )}
        {...props}
      />
    )
  },
)
ImageWrapper.displayName = 'InfoBlock.ImageWrapper'

const Content = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        'tw-flex tw-h-full tw-w-full tw-flex-col tw-justify-center',
        className,
      )}
      {...props}
    />
  ),
)
Content.displayName = 'InfoBlock.Content'

const Title = forwardRef<
  ElementRef<typeof Heading>,
  PartialBy<ComponentPropsWithoutRef<typeof Heading>, 'type'>
>(({ className, as = 'h2', type = 'h3', ...props }, ref) => (
  <Heading
    ref={ref}
    type={type}
    as={as}
    className={cn('tw-mb-2', className)}
    {...props}
  />
))
Title.displayName = 'InfoBlock.Title'

const Description = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cn('tw-w-full', className)} {...props} />
  ),
)
Description.displayName = 'InfoBlock.Description'

const Cta = forwardRef<
  ElementRef<typeof Button>,
  ComponentPropsWithoutRef<typeof Button>
>(({ className, ...props }, ref) => {
  const infoBlockContext = useContext(InfoBlockContext)
  const ctaVariant =
    infoBlockContext === 'tertiary' ? 'secondary' : infoBlockContext

  return (
    <Button
      ref={ref}
      variant={ctaVariant}
      className={cn('tw-mt-6 tw-w-fit', className)}
      {...props}
    />
  )
})
Cta.displayName = 'InfoBlock.Cta'

export { Root, Content, ImageWrapper, Title, Description, Cta }
