/* eslint-disable sonarjs/no-duplicate-string */
'use client'

import type {
  ComponentPropsWithoutRef,
  ElementRef,
  HTMLAttributes,
  ReactElement,
  TouchEvent,
} from 'react'
import {
  useImperativeHandle,
  Children,
  forwardRef,
  useEffect,
  useState,
} from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from '@chaseweb/utils/cn'
import { Slot } from '@radix-ui/react-slot'
import { Button, Heading, ScreenReader } from '../../atoms'
import { CustomSvg } from '../../atoms/svg'
import { CarouselContextProvider, useCarouselContext } from './carousel.context'

const TOUCH_DISTANCE_TO_ACTION = 40

const rootVariants = cva('', {
  variants: {
    variant: {
      custom: 'tw-w-full',
      fullwidth: 'tw-container',
      background:
        'tw-container tw-w-full tw-pt-[calc(100vw_*_0.4)] lg:tw-pt-[calc(100vw_*_0.35)]',
    },
  },
  defaultVariants: {
    variant: 'fullwidth',
  },
})

interface RootProps
  extends HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof rootVariants> {
  variant?: 'fullwidth' | 'background' | 'custom'
  classNameWrapper?: string
  maxPageSize?: 1 | 2 | 3 | 4
  asChild?: boolean
  context?: any
}

const Root = forwardRef<HTMLDivElement, RootProps>(
  (
    {
      className,
      classNameWrapper,
      context = {},
      asChild,
      variant = 'fullwidth',
      maxPageSize = 3,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'div'
    return (
      <CarouselContextProvider
        variant={variant}
        maxPageSize={maxPageSize}
        {...context}
      >
        <div
          className={cn(
            'ui-no-scrollbar tw-relative tw-flex tw-w-full tw-overflow-hidden',
            classNameWrapper,
          )}
        >
          <Comp
            ref={ref}
            className={cn(rootVariants({ variant }), className)}
            {...props}
          />
        </div>
      </CarouselContextProvider>
    )
  },
)
Root.displayName = 'Carousel.Root'

const Title = forwardRef<
  ElementRef<typeof Heading>,
  PartialBy<ComponentPropsWithoutRef<typeof Heading>, 'type'>
>(({ className, type = 'h2', ...props }, ref) => (
  <Heading ref={ref} type={type} className={cn(className)} {...props} />
))
Title.displayName = 'Carousel.Title'

export interface DescriptionProps extends HTMLAttributes<HTMLParagraphElement> {
  asChild?: boolean
}

const Description = forwardRef<HTMLParagraphElement, DescriptionProps>(
  ({ className, asChild, ...props }, ref) => {
    const Comp = asChild ? Slot : 'p'
    return <Comp ref={ref} className={cn('tw-mt-6', className)} {...props} />
  },
)
Description.displayName = 'Carousel.Description'

interface ContentProps extends HTMLAttributes<HTMLUListElement> {
  children: ReactElement | ReactElement[]
  classNameItem?: string
  'data-testid'?: string
}

const Content = forwardRef<HTMLUListElement | null, ContentProps>(
  (
    {
      className,
      'data-testid': dataTestId = 'carousel-content',
      classNameItem,
      children,
      ...props
    },
    ref,
  ) => {
    const {
      variant,
      maxPageSize,
      setMaxPageSize,
      containerRef,
      childrenCount,
      setChildrenCount,
      contentRefList,
      pageCount,
      setPageCount,
      pageSize,
      setPageSize,
      currentPage,
      setCurrentPage,
    } = useCarouselContext()
    const [touchPositionStart, setTouchPositionStart] = useState(0)

    /* istanbul ignore next - can't test refs easily in jest */
    useImperativeHandle<HTMLUListElement | null, HTMLUListElement | null>(
      ref,
      () => containerRef.current,
      [],
    )

    useEffect(() => {
      const newChildrenCount = Children.count(children)
      setChildrenCount(newChildrenCount)
      if (newChildrenCount < maxPageSize) {
        setMaxPageSize(newChildrenCount as 1 | 2 | 3 | 4)
      }
    }, [children])

    useEffect(() => {
      let windowWidth = 0 // need handleResize to run at least once on load

      const handleResize = () => {
        if (
          window.innerWidth !== windowWidth &&
          containerRef.current?.scrollTo &&
          contentRefList.current?.[0]
        ) {
          windowWidth = window.innerWidth
          containerRef.current.scrollTo({
            left: 0,
          })
          setCurrentPage(0)

          if (variant === 'background') {
            setPageCount(childrenCount)
            setPageSize(1)
          } else {
            const computedSizeContainer = getComputedStyle(containerRef.current)
            const containerWidth =
              containerRef.current.clientWidth -
              parseFloat(computedSizeContainer.paddingLeft) -
              parseFloat(computedSizeContainer.paddingRight)
            const itemWidth = contentRefList.current[0].clientWidth
            const newPageSize: number = Math.min(
              Math.round(containerWidth / (itemWidth || 1) || 1),
              maxPageSize,
              childrenCount,
            )
            setPageCount(Math.ceil(childrenCount / newPageSize))
            setPageSize(newPageSize)
          }
        }
      }
      window.addEventListener('resize', handleResize, false)
      handleResize()
      return () => {
        window.removeEventListener('resize', handleResize, false)
      }
    }, [childrenCount])

    const handleRefAssign = (index: number) => (el: HTMLLIElement) => {
      contentRefList.current[index] = el
    }

    const handleTouchStart = (e: TouchEvent) => {
      setTouchPositionStart(e.touches[0].clientX)
    }

    const handleTouchEnd = (e: TouchEvent) => {
      const touchPositionEnd = e.changedTouches[0].clientX
      const touchDistanceDifference = touchPositionEnd - touchPositionStart

      if (
        currentPage > 0 &&
        touchDistanceDifference > TOUCH_DISTANCE_TO_ACTION
      ) {
        setCurrentPage((currentPage) => currentPage - 1)
      } else if (
        currentPage < pageCount - 1 &&
        touchDistanceDifference < -TOUCH_DISTANCE_TO_ACTION
      ) {
        setCurrentPage((currentPage) => currentPage + 1)
      }
    }

    return (
      <div
        className={cn(
          '-tw-mx-4 tw-touch-pan-x tw-touch-pan-y md:-tw-mx-24 lg:-tw-mx-2',
          {
            'md:-tw-mx-22 lg:tw-mx-0': variant === 'background',
            'md:-tw-mx-2': variant === 'custom',
            'tw-pb-2': childrenCount === pageSize,
          },
        )}
      >
        <ul
          data-testid={dataTestId}
          data-page-size={pageSize}
          data-current-page={currentPage}
          ref={containerRef}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          className={cn(
            'tw-group/carousel ui-no-scrollbar tw-grid tw-w-full tw-grid-flow-col tw-overflow-hidden tw-pb-6',
            {
              'tw-ml-auto tw-max-w-[100vw] tw-auto-cols-[100%] tw-px-8 md:tw-px-20 lg:-tw-mx-[109px] lg:tw-w-screen lg:tw-pl-[calc(100vw_-_660px)] lg:tw-pr-[101px] xl:tw-w-[calc(100%_+_820px)] xl:tw-max-w-none xl:tw-px-[calc(100%_-_450px)]':
                variant === 'background',
              ' tw-auto-cols-[calc(90vw_-_32px)] tw-px-2 tw-pt-10 md:tw-auto-cols-[50%] md:tw-px-22 lg:tw-px-0 lg:tw-pt-12':
                variant !== 'background',
            },
            {
              'tw-auto-cols-[100%]': childrenCount === 1,
              'xl:tw-auto-cols-[25%]': maxPageSize === 4,
              'xl:tw-auto-cols-[33.33%]': maxPageSize === 3,
              'xl:tw-auto-cols-[50%]': maxPageSize === 2,
              'md:tw-auto-cols-[100%] xl:tw-auto-cols-[100%]':
                maxPageSize === 1,
              'md:tw-px-0': variant === 'custom',
            },
            className,
          )}
          {...props}
        >
          {Children.map(children, (child: ReactElement, index) => {
            const isActive = index === pageSize * currentPage
            const position = index < currentPage ? 'prev' : 'next'
            return (
              <ContentItem
                ref={handleRefAssign(index)}
                data-position={isActive ? 'active' : position}
                data-index={index}
                data-active={isActive}
                className={cn(classNameItem)}
              >
                {child}
              </ContentItem>
            )
          })}
        </ul>
      </div>
    )
  },
)
Content.displayName = 'Carousel.Content'

const ContentItem = forwardRef<
  HTMLLIElement,
  HTMLAttributes<HTMLLIElement> & { 'data-index': number }
>(({ className, 'data-index': dataIndex, ...props }, ref) => {
  const { variant, currentPage, pageSize } = useCarouselContext()
  const currentPageItemIndex = pageSize * currentPage
  return (
    <li
      ref={ref}
      className={cn(
        'tw-group/citem tw-flex tw-h-full tw-w-full tw-snap-center tw-px-2 tw-opacity-100 [&>*]:tw-min-h-full [&>*]:tw-min-w-full',
        ...(variant === 'background'
          ? [
              '[&>*]:tw-max-w-full [&>*]:tw-transition-all [&>*]:tw-duration-300',
              {
                '[&>*>*]:tw-opacity-0 [&>*]:tw-bg-transparent [&>*]:tw-from-white [&>*]:tw-to-transparent [&>*]:tw-to-15% [&>*]:tw-text-transparent [&>*]:tw-shadow-none':
                  dataIndex < currentPageItemIndex ||
                  dataIndex > currentPage + pageSize - 1,
                '[&>*]:tw-bg-gradient-to-r':
                  dataIndex === currentPage + pageSize,
                '[&>*]:tw-bg-gradient-to-l':
                  dataIndex === currentPageItemIndex - 1,
              },
            ]
          : []),
        className,
      )}
      {...props}
    />
  )
})
ContentItem.displayName = 'Carousel.ContentItem'

const BackgroundImageWrapper = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => {
  return (
    <div
      ref={ref}
      {...props}
      className={cn(
        'tw-absolute tw-left-0 tw-top-0 -tw-z-10 tw-flex tw-w-screen tw-items-center tw-justify-center tw-overflow-hidden',
        'tw-h-[calc(100vw_*_0.4_+_120px)] tw-opacity-0 tw-transition-opacity tw-duration-300 group-data-[position=active]/citem:tw-opacity-100 xl:tw-h-[calc(100vw_*_0.35_+_170px)]',
        'group-data-[position=next]/citem:[&>*]:tw-translate-x-10 group-data-[position=prev]/citem:[&>*]:-tw-translate-x-10 [&_*]:tw-w-[calc(100vw_+_80px)] [&_*]:tw-min-w-[calc(100vw_+_80px)] [&_*]:tw-transition-transform [&_*]:tw-duration-300 xl:[&_*]:tw-max-w-none',
        className,
      )}
    >
      {children}
    </div>
  )
})
BackgroundImageWrapper.displayName = 'Carousel.BackgroundImageWrapper'

const Controls = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & { preRender?: boolean }
>(({ className, children, preRender, ...props }, ref) => {
  const { variant, childrenCount, pageSize } = useCarouselContext()
  if (childrenCount === 0 && preRender) {
    return (
      <div
        className={cn('tw-flex tw-opacity-0', {
          'md:tw-mt-2': variant !== 'background',
        })}
        {...props}
      >
        <ControlsArrowsContainer>
          <ControlsArrowsLeft />
          <ControlsArrowsRight />
        </ControlsArrowsContainer>
      </div>
    )
  }

  return childrenCount > pageSize ? (
    <div
      ref={ref}
      className={cn(
        'tw-flex tw-justify-center md:tw-justify-between',
        {
          'tw-w-full lg:tw-ml-auto lg:tw-w-[537px] xl:tw-w-[546px]':
            variant === 'background',
          'md:tw-mt-2': variant !== 'background',
        },
        className,
      )}
      {...props}
    >
      {children ?? (
        <>
          <ControlsPageDots />
          <ControlsArrowsContainer>
            <ControlsArrowsLeft />
            <ControlsArrowsRight />
          </ControlsArrowsContainer>
        </>
      )}
    </div>
  ) : null
})
Controls.displayName = 'Carousel.Controls'

interface ControlsPageDotsProps extends HTMLAttributes<HTMLDivElement> {
  classNameDots?: string
  'data-testid'?: string
}

const ControlsPageDots = forwardRef<HTMLDivElement, ControlsPageDotsProps>(
  (
    {
      className,
      'data-testid': dataTestId = 'carousel-page-dots',
      classNameDots,
      ...props
    },
    ref,
  ) => {
    const { pageCount, currentPage, setCurrentPage } = useCarouselContext()

    const dotArray = Array.from({ length: pageCount }, (_, i) => i)

    const handleDotClick = (index: number) => () => {
      setCurrentPage(index)
    }

    const distanceBlueDot = (currentPage * 38) / 16 // 38 is the distance between the dots and 16 is the base font size

    return (
      <div
        ref={ref}
        className={cn(
          'tw-relative tw-flex tw-h-14 -tw-translate-x-3 tw-items-center',
          className,
        )}
        {...props}
      >
        <div
          data-testid={`${dataTestId}-control`}
          className={cn(
            'tw-absolute tw-left-3.5 tw-h-3 tw-w-3 tw-rounded-full tw-bg-blue30 tw-transition-transform tw-duration-250 tw-ease-in-out',
          )}
          style={{
            transform: `translate(${distanceBlueDot}rem)`,
          }}
        />
        {dotArray.map((dotIndex) => (
          <Button
            key={dotIndex}
            data-testid={`${dataTestId}-${dotIndex}`}
            className={cn(
              'tw-flex tw-h-full tw-cursor-pointer tw-items-center tw-px-4',
              classNameDots,
            )}
            onClick={handleDotClick(dotIndex)}
            variant="link"
          >
            <div
              className={cn('tw-h-1.5 tw-w-1.5 tw-rounded-full tw-bg-grey60')}
            />
            <ScreenReader.Message>
              Go to page {dotIndex + 1}
            </ScreenReader.Message>
          </Button>
        ))}
      </div>
    )
  },
)
ControlsPageDots.displayName = 'Carousel.ControlsPageDots'

const ControlsArrowsContainer = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      'tw-hidden tw-w-0 tw-gap-4 md:tw-flex md:tw-w-auto',
      className,
    )}
    {...props}
  />
))
ControlsArrowsContainer.displayName = 'Carousel.ControlsArrowsContainer'

const ControlsArrowsLeft = forwardRef<
  ElementRef<typeof Button>,
  ComponentPropsWithoutRef<typeof Button> & { 'data-testid'?: string }
>(
  (
    { className, 'data-testid': dataTestId = 'carousel-left-arrow', ...props },
    ref,
  ) => {
    const { currentPage, setCurrentPage } = useCarouselContext()

    const handleClickLeftPage = () => {
      setCurrentPage((currentPage: number) => currentPage - 1)
    }

    return (
      <Button
        data-testid={dataTestId}
        ref={ref}
        variant="secondary"
        size="sm"
        className={cn(
          'tw-flex tw-h-14 tw-w-14 tw-border-secondaryBorder tw-p-0',
          className,
        )}
        disabled={currentPage === 0}
        onClick={handleClickLeftPage}
        {...props}
      >
        <CustomSvg name="ArrowLeft" className={cn('tw-h-8 tw-w-8')} />

        <ScreenReader.Message>Previous carousel page</ScreenReader.Message>
      </Button>
    )
  },
)
ControlsArrowsLeft.displayName = 'Carousel.ControlsArrowsLeft'

const ControlsArrowsRight = forwardRef<
  ElementRef<typeof Button>,
  ComponentPropsWithoutRef<typeof Button> & { 'data-testid'?: string }
>(
  (
    { className, 'data-testid': dataTestId = 'carousel-right-arrow', ...props },
    ref,
  ) => {
    const { pageCount, currentPage, setCurrentPage } = useCarouselContext()

    const handleClickRightPage = () => {
      setCurrentPage((currentPage: number) => currentPage + 1)
    }

    return (
      <Button
        data-testid={dataTestId}
        ref={ref}
        variant="secondary"
        size="sm"
        className={cn(
          'tw-flex tw-h-14 tw-w-14 tw-border-secondaryBorder tw-p-0',
          className,
        )}
        disabled={currentPage === pageCount - 1}
        onClick={handleClickRightPage}
        {...props}
      >
        <CustomSvg name="ArrowRight" className={cn('tw-h-8 tw-w-8')} />

        <ScreenReader.Message>Next carousel page</ScreenReader.Message>
      </Button>
    )
  },
)
ControlsArrowsRight.displayName = 'Carousel.ControlsArrowsRight'

export {
  Root,
  Title,
  Description,
  Content,
  BackgroundImageWrapper,
  Controls,
  ControlsPageDots,
  ControlsArrowsContainer,
  ControlsArrowsLeft,
  ControlsArrowsRight,
}
