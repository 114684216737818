import { cn } from '@chaseweb/utils/cn'
import type {
  ComponentPropsWithoutRef,
  ElementRef,
  HTMLAttributes,
} from 'react'
import { Children, forwardRef } from 'react'
import { Heading, CustomSvg } from '../../atoms'
import { Slot } from '@radix-ui/react-slot'
import * as SeparatorRadix from '@radix-ui/react-separator'
import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'

const Root = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, ref) => {
    const childrenCount = Children.count(children)
    if (childrenCount !== 2) throw new Error('There should be 2 items')
    return (
      <div
        className={cn(
          'tw-flex tw-flex-col tw-gap-4 lg:tw-flex-row lg:tw-gap-6',
        )}
        ref={ref}
        {...props}
      >
        {children}
      </div>
    )
  },
)
Root.displayName = 'ComparisonList.Root'

const Item = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, ref) => {
    return (
      <div
        className={cn(
          'tw-flex tw-w-full tw-flex-col tw-gap-6 tw-rounded-2xl tw-border tw-border-grey70 tw-px-8 tw-pb-10 tw-pt-8 lg:tw-gap-8 lg:tw-rounded-3xl lg:tw-px-10 lg:tw-pb-12 lg:tw-pt-10',
        )}
        ref={ref}
        {...props}
      >
        {children}
      </div>
    )
  },
)
Item.displayName = 'ComparisonList.Item'

const Header = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      {...props}
      ref={ref}
      className={cn(
        'tw-flex tw-w-full tw-flex-col tw-gap-6 md:tw-flex-row md:tw-flex-nowrap md:tw-gap-x-6 md:tw-gap-y-8',
      )}
    />
  ),
)
Header.displayName = 'ComparisonList.Header'

const ImageWrapper = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & {
    asChild?: boolean
  }
>(({ className, asChild, ...props }, ref) => {
  const Comp = asChild ? Slot : 'div'
  return (
    <Comp
      className={cn(
        'tw-flex tw-h-fit tw-w-[145px] tw-rounded-lg tw-shadow-image',
      )}
      ref={ref}
      {...props}
    ></Comp>
  )
})
ImageWrapper.displayName = 'ComparisonList.ImageWrapper'

const Title = forwardRef<
  ElementRef<typeof Heading>,
  PartialBy<ComponentPropsWithoutRef<typeof Heading>, 'type'>
>(({ className, type, ...props }, ref) => (
  <Heading
    {...props}
    ref={ref}
    className={cn('md:tw-self-center')}
    type={'h2'}
    {...props}
  />
))
Title.displayName = 'ComparisonList.Title'

interface SeparatorProps extends SeparatorRadix.SeparatorProps {}

const Separator = forwardRef<
  ElementRef<typeof SeparatorRadix.Root>,
  SeparatorProps
>(({ className, ...props }, ref) => (
  <div className="tw-w-full tw-border-t tw-border-dashed tw-border-grey70 tw-pb-2 md:tw-mt-2 lg:tw-mt-0">
    <SeparatorRadix.Root ref={ref} className={cn()} {...props} />
  </div>
))
Separator.displayName = 'ComparisonList.Separator'

const Content = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, ref) => {
    return (
      <div
        className={cn('tw-flex tw-w-full tw-flex-col tw-gap-6 lg:tw-gap-8')}
        ref={ref}
        {...props}
      >
        {children}
      </div>
    )
  },
)
Content.displayName = 'ComparisonList.Content'

const Feature = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, ref) => {
    return (
      <div
        className={cn('tw-grid tw-grid-cols-[32px_auto] tw-gap-6')}
        ref={ref}
        {...props}
      >
        {children}
      </div>
    )
  },
)
Feature.displayName = 'ComparisonList.Feature'

const iconVariants = cva(
  'tw-row-span-[1/3] tw-flex tw-w-8 tw-items-start tw-bg-no-repeat tw-text-grey40',
  {
    variants: {
      iconName: {
        tick: 'tw-text-blue30',
      },
    },
  },
)

interface IconProps
  extends HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof iconVariants> {}

const Icon = forwardRef<HTMLDivElement, IconProps>(
  ({ className, iconName, children, ...props }, ref) => {
    return (
      <div
        className={cn(iconVariants({ iconName }), className)}
        ref={ref}
        {...props}
      >
        {children}

        <CustomSvg
          name={iconName === 'tick' ? 'Tick' : 'Dash'}
          data-testid={`iconName-${iconName ?? 'null'}`}
          className="tw-w-8"
        />
      </div>
    )
  },
)
Icon.displayName = 'ComparisonList.Icon'

const Label = forwardRef<HTMLDivElement, HTMLAttributes<HTMLParagraphElement>>(
  ({ className, children, ...props }, ref) => {
    return (
      <div className={cn('tw-font-bold')} ref={ref} {...props}>
        {children}
      </div>
    )
  },
)
Label.displayName = 'ComparisonList.Label'

const TextWrapper = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLParagraphElement>
>(({ className, children, ...props }, ref) => {
  return (
    <div className={cn('tw-flex tw-flex-col')} ref={ref} {...props}>
      {children}
    </div>
  )
})
TextWrapper.displayName = 'ComparisonList.TextWrapper'

const Legal = forwardRef<HTMLDivElement, HTMLAttributes<HTMLParagraphElement>>(
  ({ className, children, ...props }, ref) => {
    return (
      <div
        className={cn(
          'tw-mr-2 tw-mt-2 tw-text-sm tw-font-normal tw-text-grey40',
        )}
        ref={ref}
        {...props}
      >
        {children}
      </div>
    )
  },
)
Legal.displayName = 'ComparisonList.Legal'

const Description = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLParagraphElement>
>(({ className, children, ...props }, ref) => {
  return (
    <div className={cn('tw-mt-1')} ref={ref} {...props}>
      {children}
    </div>
  )
})
Description.displayName = 'ComparisonList.Description'

export {
  Root,
  Item,
  Header,
  ImageWrapper,
  Title,
  Separator,
  Content,
  Feature,
  TextWrapper,
  Description,
  Legal,
  Icon,
  Label,
}
