'use client'

import { Button } from '@chaseweb/ui-library/src'
import { useCallback, useEffect, useRef } from 'react'

const INTERACTIVE_ELEMENT_SELECTOR =
  'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'

export const SKIP_TO_CONTENT = 'Skip to main content'

const getFirstInteractiveContentElement = (): HTMLElement | null => {
  const contentContainer = document.querySelector(
    'main, .breadcrumbs, .ui-Breadcrumb',
  )
  let firstInteractiveElement = contentContainer?.querySelector(
    INTERACTIVE_ELEMENT_SELECTOR,
  )
  if (!firstInteractiveElement) {
    const footer = document.querySelector('footer')
    firstInteractiveElement =
      footer?.querySelector(INTERACTIVE_ELEMENT_SELECTOR) ?? null
  }
  return firstInteractiveElement as HTMLElement
}

export const SkipToMainContentButton = () => {
  const ref = useRef<HTMLButtonElement>(null)

  const captureFirstTabEvent = useCallback<(e: KeyboardEvent) => void>(
    (e) => {
      if (
        e.key === 'Tab' &&
        document.activeElement === document.querySelector('body')
      ) {
        e.preventDefault()
        ref.current?.focus()
      }
    },
    [ref],
  )

  useEffect(() => {
    // on page load, even if the user has scrolled down or clicked elsewhere on the page,
    // make sure the first tab event causes this button to be focused.
    document.addEventListener('keydown', captureFirstTabEvent)
    return () => document.removeEventListener('keydown', captureFirstTabEvent)
  }, [captureFirstTabEvent])

  const handleSkipToMainContent = () => {
    const focusableElement = getFirstInteractiveContentElement()
    focusableElement && focusableElement.focus()
  }

  return (
    <Button
      ref={ref}
      variant="secondary"
      onClick={handleSkipToMainContent}
      size="sm"
      className="tw-absolute -tw-left-full tw-top-10 tw-z-30 tw-m-1 tw-opacity-0 tw-transition-opacity focus-within:tw-left-0 focus-within:tw-opacity-100 lg:tw-top-16"
    >
      {SKIP_TO_CONTENT}
    </Button>
  )
}
