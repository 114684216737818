'use client'

import { cn } from '@chaseweb/utils/cn'
import type { KeyboardEvent, MouseEventHandler } from 'react'

import { useConsentStore } from '@/lib/stores'

export function ConsentModalLink({
  className,
  children,
}: {
  readonly className?: string
  readonly children: string
}) {
  const setCurrentStep = useConsentStore(({ setCurrentStep }) => setCurrentStep)

  const onClick: MouseEventHandler = (event) => {
    event.preventDefault()
    setCurrentStep(0)
  }
  const handleKeyPress = (event: KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      setCurrentStep(0)
    }
  }

  return (
    <button
      className={cn('tw-cursor-pointer', className)}
      onClick={onClick}
      onKeyDown={handleKeyPress}
    >
      {children}
    </button>
  )
}
