/* eslint-disable sonarjs/no-duplicate-string */
'use client'

import { forwardRef, useEffect, useRef, useState } from 'react'
import type {
  ComponentPropsWithoutRef,
  HTMLAttributes,
  ElementRef,
  MouseEvent,
  PointerEvent,
} from 'react'
import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import { Slot } from '@radix-ui/react-slot'
import { cn } from '@chaseweb/utils/cn'
import { Button, CustomSvg, HamburgerIcon } from '../../atoms'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import { useIsMobileBreakpoint } from '@chaseweb/utils/useIsMobileBreakpoint'

const preventDefaultCb = <T extends HTMLElement>(event: PointerEvent<T>) => {
  event.preventDefault()
  event.stopPropagation()
}

const hamburgerClasses =
  'tw-right-3 tw-top-[18px] tw-z-50 tw-flex tw-h-6 tw-w-6 tw-items-center tw-overflow-visible'

interface RootProps {
  contentClassName?: string
}

const Root = forwardRef<
  ElementRef<typeof NavigationMenu.Root>,
  ComponentPropsWithoutRef<typeof NavigationMenu.Root> & RootProps
>(({ className, children, contentClassName, ...props }, ref) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const isMobile = useIsMobileBreakpoint()
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsSideMenuOpen(false)
    }
    window.addEventListener('resize', handleResize, false)
    return () => {
      window.removeEventListener('resize', handleResize, false)
    }
  }, [])

  return isMobile ? (
    <div className={cn('tw-relative lg:tw-hidden')}>
      <DialogPrimitive.Root
        open={isSideMenuOpen}
        onOpenChange={() => {
          setIsSideMenuOpen(!isSideMenuOpen)
        }}
      >
        <DialogPrimitive.Trigger asChild>
          <Button
            aria-label="open navigation"
            variant="link"
            className={cn('tw-static tw-justify-end', hamburgerClasses)}
          >
            <HamburgerIcon active={isSideMenuOpen} activeOnMount={false} />
          </Button>
        </DialogPrimitive.Trigger>
        <DialogPrimitive.Portal>
          {isSideMenuOpen && (
            <>
              <div
                onClick={() => {
                  setIsSideMenuOpen(false)
                }}
                aria-hidden="true"
                data-testid="overlay-backdrop"
                className={cn(
                  'tw-fixed tw-left-0 tw-top-0 tw-z-30 tw-h-screen tw-w-screen tw-bg-grey10 tw-opacity-30',
                  'data-[state=open]:tw-animate-backdropFadeIn',
                  'data-[state=closed]:tw-animate-backdropFadeOut',
                )}
              />
              <DialogPrimitive.Overlay
                data-testid="overlay"
                forceMount
                className={cn(
                  'tw-group/overlay tw-fixed tw-right-0 tw-top-0 tw-z-40 tw-h-screen tw-w-full lg:tw-hidden',
                  'data-[state=open]:tw-animate-slideFromRight',
                  'data-[state=closed]:tw-animate-sidePanelFadeOut',
                )}
              >
                <DialogPrimitive.Content>
                  <NavigationMenu.Root
                    tabIndex={0}
                    ref={ref}
                    className={cn('tw-h-full', className)}
                    {...props}
                  >
                    <div
                      data-testid="list-mobile"
                      onPointerDown={preventDefaultCb}
                      ref={containerRef}
                      className={cn(
                        'tw-flex tw-h-full tw-w-full tw-items-center lg:tw-hidden',
                      )}
                    >
                      <div
                        className={cn(
                          'tw-fixed tw-right-0 tw-top-0 tw-z-40 tw-h-screen tw-w-full tw-bg-white tw-font-semibold tw-shadow-popup md:tw-w-[calc(100vw_-_9.25rem)]',
                          contentClassName,
                        )}
                      >
                        <NavigationMenu.List
                          key="mobile-navigation-list"
                          {...props}
                          className={cn(
                            'tw-flex tw-max-h-screen tw-flex-col tw-items-baseline tw-gap-8 tw-overflow-auto tw-overscroll-contain tw-px-4 tw-py-16 md:tw-px-16 lg:tw-hidden',
                            'group-data-[state=closed]/overlay:tw-animate-fadeOut group-data-[state=open]/overlay:tw-animate-fadeIn',
                          )}
                        >
                          {children}
                        </NavigationMenu.List>
                      </div>
                    </div>
                  </NavigationMenu.Root>
                  <DialogPrimitive.Close asChild>
                    {/* The below empty button is a hack that allows the close button to be focusable */}
                    <Button
                      aria-label="close navigation"
                      variant="link"
                      className={cn(
                        'tw-fixed tw-justify-center',
                        hamburgerClasses,
                      )}
                    />
                  </DialogPrimitive.Close>
                </DialogPrimitive.Content>
              </DialogPrimitive.Overlay>
              <Button
                variant="link"
                aria-hidden
                className={cn('tw-fixed tw-justify-center', hamburgerClasses)}
              >
                <HamburgerIcon active={isSideMenuOpen} activeOnMount={false} />
              </Button>
            </>
          )}
        </DialogPrimitive.Portal>
      </DialogPrimitive.Root>
    </div>
  ) : (
    <div className={cn('tw-hidden lg:tw-block')}>
      <NavigationMenu.Root
        ref={ref}
        className={cn('tw-h-full', className)}
        {...props}
      >
        <div
          ref={containerRef}
          data-testid="list-desktop"
          className={cn(
            'tw-hidden tw-h-full tw-w-full tw-items-center lg:tw-flex',
          )}
        >
          <div className={cn('tw-font-semibold', contentClassName)}>
            <NavigationMenu.List
              {...props}
              className={cn('tw-flex tw-items-center tw-gap-14')}
            >
              {children}
            </NavigationMenu.List>
          </div>
        </div>
      </NavigationMenu.Root>
    </div>
  )
})
Root.displayName = 'Navigation.Root'

const MenuItem = forwardRef<
  ElementRef<typeof NavigationMenu.Item>,
  ComponentPropsWithoutRef<typeof NavigationMenu.Item>
>(({ className, ...props }, ref) => (
  <NavigationMenu.Item
    ref={ref}
    className={cn(
      'tw-relative tw-flex tw-flex-col tw-justify-center group-[&]/list:tw-flex-col group-[&]/content:tw-justify-start lg:tw-flex-row',
      className,
    )}
    {...props}
  />
))
MenuItem.displayName = 'Navigation.MenuItem'

interface LinkIconWrapperProps extends HTMLAttributes<HTMLDivElement> {
  asChild?: boolean
}
const LinkIconWrapper = forwardRef<HTMLDivElement, LinkIconWrapperProps>(
  ({ className, asChild, ...props }, ref) => {
    const Comp = asChild ? Slot : 'div'
    return (
      <Comp
        ref={ref}
        aria-hidden
        className={cn(
          'ui-navigation-iconwrapper tw-mr-4 tw-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-xl tw-border tw-border-grey80',
          className,
        )}
        {...props}
      />
    )
  },
)

LinkIconWrapper.displayName = 'Navigation.LinkIconWrapper'

interface LinkTextWrapperProps extends HTMLAttributes<HTMLDivElement> {
  asChild?: boolean
}
const LinkTextWrapper = forwardRef<HTMLDivElement, LinkTextWrapperProps>(
  ({ className, asChild, ...props }, ref) => {
    const Comp = asChild ? Slot : 'div'
    return (
      <Comp
        ref={ref}
        className={cn('ui-navigation-textwrapper', className)}
        {...props}
      />
    )
  },
)

LinkTextWrapper.displayName = 'Navigation.LinkTextWrapper'

interface LinkProps extends ComponentPropsWithoutRef<typeof Button> {
  active?: boolean
  hasSubmenu?: boolean
}
const Link = forwardRef<ElementRef<typeof Button>, LinkProps>(
  ({ className, onClick, hasSubmenu, active, ...props }, ref) => {
    const handleOnClick = (ev: MouseEvent<HTMLButtonElement>) => {
      if (!hasSubmenu) {
        window.dispatchEvent(new Event('resize'))
        const keyboardEvent = new KeyboardEvent('keydown', {
          key: 'Escape',
        })
        document.dispatchEvent(keyboardEvent)
      }
      onClick?.(ev)
    }
    return (
      <Button
        variant="link"
        role={hasSubmenu ? 'button' : 'link'}
        ref={ref}
        className={cn(
          'tw-flex tw-w-fit tw-cursor-pointer tw-items-center tw-whitespace-nowrap tw-py-0.5 tw-text-text [&:has(.ui-navigation-iconwrapper)]:tw-font-normal',
          'hover:tw-text-blue30 hover:tw-no-underline',
          {
            '[&>.ui-navigation-textwrapper]:tw-shadow-underscore-lg': active,
          },
          className,
        )}
        onClick={handleOnClick}
        {...props}
      />
    )
  },
)
Link.displayName = 'Navigation.Link'

const Trigger = forwardRef<
  ElementRef<typeof NavigationMenu.Trigger>,
  ComponentPropsWithoutRef<typeof NavigationMenu.Trigger>
>(({ className, children, ...props }, ref) => {
  return (
    <Link asChild role="button" hasSubmenu={true}>
      <NavigationMenu.Trigger
        onPointerMove={preventDefaultCb}
        onPointerLeave={preventDefaultCb}
        ref={ref}
        className={cn(
          'tw-group/trigger tw-w-fit focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-focusRing',
          className,
          'tw-gap-0',
        )}
        {...props}
      >
        {children}
        <CustomSvg
          name="ArrowDown"
          className="tw-w-6 tw-text-grey10 tw-transition-transform group-data-[state=open]/trigger:tw-rotate-180"
        />
      </NavigationMenu.Trigger>
    </Link>
  )
})

Trigger.displayName = 'Navigation.Trigger'

const Content = forwardRef<
  ElementRef<typeof NavigationMenu.Content>,
  ComponentPropsWithoutRef<typeof NavigationMenu.Content>
>(({ className, ...props }, ref) => {
  return (
    <NavigationMenu.Content
      onPointerLeave={preventDefaultCb}
      ref={ref}
      className={cn(
        'tw-group/content tw-top-8 tw-z-50 tw-flex tw-flex-col tw-flex-nowrap tw-gap-10 tw-p-6 ',
        'lg:tw-absolute lg:tw-flex-row lg:tw-gap-14 lg:tw-rounded-3xl lg:tw-bg-white lg:tw-p-8 lg:tw-pr-10 lg:tw-shadow-popup ',
        'data-[state=open]:tw-animate-fadeIn lg:data-[state=open]:tw-animate-mount',
        'data-[state=closed]:tw-animate-fadeOut lg:data-[state=closed]:tw-animate-unmount',
        className,
      )}
      {...props}
    />
  )
})

Content.displayName = 'Navigation.Content'

const SubList = forwardRef<
  ElementRef<typeof NavigationMenu.List>,
  ComponentPropsWithoutRef<typeof NavigationMenu.List>
>(({ className, ...props }, ref) => (
  <NavigationMenu.Sub>
    <NavigationMenu.List
      ref={ref}
      className={cn('tw-flex tw-flex-col tw-gap-3', className)}
      {...props}
    />
  </NavigationMenu.Sub>
))
SubList.displayName = 'Navigation.SubList'

const SubListHeader = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn('tw-font-semibold', className)} {...props} />
))
SubListHeader.displayName = 'Navigation.SubListHeader'

export {
  Root,
  MenuItem,
  LinkIconWrapper,
  LinkTextWrapper,
  Link,
  Trigger,
  Content,
  SubList,
  SubListHeader,
}
