'use client'

import { forwardRef } from 'react'
import { cn } from '@chaseweb/utils/cn'
import { CustomSvg } from '../svg'
import type { ReactNode } from 'react'
import type { ToastProps } from '@radix-ui/react-toast'
import * as ToastPrimitive from '@radix-ui/react-toast'

export interface ToastPropsPlus extends ToastProps {
  children?: ReactNode
  setShowToast: (Toast: boolean) => void
  showToast: boolean
  duration: number
}

const Toast = forwardRef<HTMLLIElement, ToastPropsPlus>(
  ({ children, duration, setShowToast, showToast }, ref) => {
    return (
      <ToastPrimitive.Provider>
        <ToastPrimitive.Root
          type="foreground"
          ref={ref}
          open={showToast}
          onOpenChange={() => {
            setShowToast(false)
          }}
          aria-live="polite"
          duration={duration}
          className={cn(
            'tw-fixed tw-bottom-4 tw-left-1/2 tw-w-[calc(100%_-_32px)] tw-max-w-[375px] -tw-translate-x-1/2',
            'tw-flex tw-gap-2 tw-rounded-2xl tw-bg-grey10 tw-p-4 tw-text-white tw-shadow-toast',
            'tw-z-50',
            {
              'tw-animate-slideFadeInFromBottomMiddle': showToast,
              'tw-animate-slideFadeOutToBottomMiddle': !showToast,
            },
          )}
        >
          <CustomSvg name="CheckFill" aria-hidden />
          <ToastPrimitive.Description>{children}</ToastPrimitive.Description>
        </ToastPrimitive.Root>
        <ToastPrimitive.Viewport />
      </ToastPrimitive.Provider>
    )
  },
)

Toast.displayName = 'Toast'

export { Toast }
